<template>
	<div>报价信息2</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		}
	}
</script>

<style scoped lang="less">
	
</style>
