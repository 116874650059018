<template>
	<div class="o">
		<el-collapse>
		      <el-collapse-item :name="index" v-for="(item,index) in data" :key="index">
		        <template #title>
					<div>{{item.title}}</div>
		        </template>
				<el-table
					:pagination="false"
					:height="300"
					:data="item.list"
					border
					ref="table">
						<lida-table-column label="产品信息" fixed>
							<template #default="scope">
								<div class="g-info">
									<div class="g-icon">
										<img :src="scope.row.src"/>
									</div>
									<div class="g-right">
										<div class="g-r-title">
											{{scope.row.productName}}
											<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
										</div>
										<div class="g-r-data">
											{{scope.row.barCode || '无'}}
											<span>|</span>
											{{scope.row.seriesName || '无'}}
											<span>|</span>
											{{scope.row.skuNumber || '无'}}
										</div>
									</div>
								</div>
							</template>
						</lida-table-column>
						<lida-table-column label="订货号" prop="articleNo" />
						<lida-table-column label="面价" prop="guidePrice" />
						<lida-table-column label="数量" prop="num" />
						<lida-table-column label="操作" fixed="right">
							<template #default="scope">
								<el-button size="mini" @click="deleteRow(scope.row)" type="text">移除</el-button>
							</template>
						</lida-table-column>
				</el-table>
		      </el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				data:[],
			}
		},
		props:['info'],
		watch:{
			info(v){
				this.data = v.forEnterprise || [];
			}
		}
	}
</script>

<style scoped lang="less">
	.o{
		height: 100%;
		overflow-y: auto;
	}
</style>
